import React from "react"
import Layout from "../components/Layout"

const TermsOfService = () => {
  return (
    <Layout>
      <div className="public_page">
        <h4>
          <strong>TERMS OF SERVICE</strong>
        </h4>
        <p>
          Welcome to AlignerBase! By clicking on the &ldquo;I Accept&rsquo;
          button that is part of the Sign-Up form, you acknowledge that you have
          read, understand, and agree, without limitation or qualification, to
          be bound and abide by this Terms of Service incorporated herein by
          reference. This page (together with the documents or links referred to
          on it tells you the terms of service (the &ldquo;Agreement&rdquo;
          &ldquo;Terms&rdquo;) which regulates and controls any access and use
          of the AlignerBase Platform (the &ldquo;Platform&rdquo;) through{" "}
          <a href="https://www.alignerbase.com">www.alignerbase.com</a> (the
          &ldquo;Website&rdquo;), owned and operated by Alignerbase LLC
          (&ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;). When any user
          (&ldquo;subscriber&rdquo;, Client, &ldquo;customer&rdquo;,
          &ldquo;you&rdquo;, &ldquo;your&rdquo;, &ldquo;their&rdquo; etc. each a
          &ldquo;user&rdquo; etc.) register, subscribe to our membership plan,
          access or use of our Platform, deem to be their sole acceptance of
          these terms of services and our privacy policy. Please read these
          terms of services carefully before you access or use of our website
          and its services. By accessing and using our website or its services,
          you agree to fully comply with and be bound by the following terms of
          services.
        </p>
        <p>
          These terms of service are a contract between you and ALIGNERBASE LLC,
          a Delaware limited liability company at{" "}
          <em>1007 N Orange St Ste 1357, 4th Floor, Wilmington, DE 19801 </em>
          and its subsidiaries. Other than as expressly stated herein, there are
          no third-party beneficiaries of this Agreement.
        </p>
        <p>
          We highly recommend you to read the following terms of services before
          accessing and using our website or Platform and its services. If you
          do not agree with these terms, including changes thereto, do not
          access or use our website and its services. We reserve the right to
          update, change or replace any part of these terms of services by
          posting updates and/or changes to our website. It is your
          responsibility to check this page periodically for changes. Your
          continued use of or access to the website following the posting of any
          changes constitutes acceptance of those changes.
        </p>
        <h4>
          <strong>ACCEPTANCE OF TERMS OF SERVICE</strong>
        </h4>
        <p>
          Please read these terms of service (&ldquo;<strong>terms</strong>
          &rdquo;) carefully. Your use of our platform and its services is
          subject to these terms, and you may use the AlignerBase Platform if
          you agree to these terms. By checking a box indicating you accept
          these terms, or by accessing or using the AlignerBase Platform, you
          agree to be bound by these terms as well as the most-recent version of
          our privacy policy. These terms constitute a legally binding contract
          between you and us. If you are using our Platform on behalf of your
          entity, your use creates a legally binding contract between your
          entity and our platform. By continuing to use of our Platform, you
          consent to any changes to our terms or privacy policy. If you do not
          agree with these terms of service, you must cease visiting, accessing
          or using our Platform immediately. These terms constitute the entire
          and only agreement between you and us with respect to your use of our
          Platform and supersedes all prior or contemporaneous agreements,
          representations, warranties and/or understandings with respect to your
          use of the our Platform, the content contained therein and/or the
          analyses, research, opinions and other information provided by or
          through the website.
        </p>
        <h4>
          <strong>DISCLAIMERS</strong>
        </h4>
        <ul>
          <li>
            <strong>
              <u>General Disclaimer:</u>
            </strong>{" "}
            We are offering our website and its services &ldquo;as is,&rdquo;
            and &ldquo;as available&rdquo; basis. Under no circumstances we will
            be responsible for any loss or damage, including loss in profits,
            revenues, goodwill, or other business and non-business losses,
            personal injury or death, resulting from anyone's use of our website
            and its services. If you obtain this website and its services or any
            information contained therein in any other place which nature and
            availability are not controlled by us, trusting on such source or
            information will be at your own risk. You understand and agree that,
            to the fullest extent permitted by law, we disclaim all warranties,
            representations and endorsements, express or implied, with regard to
            the site, including, without limitation, implied warranties of
            title, merchantability, non-infringement and fitness for a
            particular purpose. &nbsp;You assume all responsibility and risk
            with respect to your use of our website and its services or access
            to any content or materials published on our website and community.
            We will not liable or responsible for any computer viruses, bugs,
            malicious code or other harmful components, delays, inaccuracies,
            errors or omissions, or the accuracy, completeness, reliability or
            usefulness of the information published, disclosed or accessed
            through the website and its services. Under no circumstances we will
            be responsible for any loss or damage, including personal injury or
            death, resulting from anyone's use of our website or its services.
            We reserve the right, in our sole discretion to reject your access
            to all or any part of our website and its services at any time, for
            any or no reason, with or without prior notice, and without
            liability.
          </li>
          <li>
            <strong>
              <u>Medical Disclaimer</u>
            </strong>
            : By using our platform, you understand and acknowledge we are not
            providing medical care, dental care, or treatment-related advice to
            you, we are merely providing professional design service; and use of
            our platform are also governed by our Business Associate Agreement
            (&ldquo;BAA&rdquo;), as it may be modified over time. We hereby also
            disclose that any of our services or contents including any
            information displayed or posted in our website are for informational
            purposes only and not intended to be a substitute for professional
            health advice or professional medical advice, diagnosis, or
            treatment. The information on our website or platform and any link
            to other sites is for informational purposes only and is not
            intended to provide medical advice for any specific medical
            condition you may have. Our platform does not create a
            doctor/patient relationship with you. We accept no liability for any
            direct, indirect, or special damages or losses of health or medical
            conditions, resulting from the use of our website and its services
            or for any reliance on any information or content posted on our
            website and its services.
          </li>
        </ul>
        <h4>
          <strong>AVAILABILITY, SECURITY &amp; ACCURACY</strong>{" "}
          <strong>OF OUR PLATFORM AND ITS SERVICES</strong>
        </h4>
        <p>
          We make no warranty that your access to our website or platform and
          its services will be uninterrupted, timely, or error-free. Due to the
          nature of the internet, this cannot be guaranteed. Also, we may
          occasionally need to carry out repairs, maintenance, or introduce new
          facilities and functions. Access to the website and its services may
          be suspended or withdrawn to or from you personally or all users
          temporarily or permanently at any time and without notice. We may also
          impose restrictions on the length and manner of usage of any part of
          our website and its services for any reason. If we impose restrictions
          on you personally, you must not attempt to use the website and its
          services under any other name or user or on any other computer,
          mobile, or similar device. We do not warrant that our website and its
          services will be compatible with all hardware and software which you
          may use. We shall not be liable for damage to, or viruses or other
          codes that may affect any device, software, data or other device or
          property as a result of your access to or use of our website and its
          services or you are obtaining any material from, or as a result of
          using it. We shall also not be liable for the actions of third
          parties. We make no representation or warranty, express or implied
          that information and materials on the website and its services are
          correct, complete, accurate, up to date, fit for a particular purpose
          and, free from errors or omissions. To the extent permitted by law, we
          do not accept any liability for any errors or omissions. However,
          nothing shall affect any obligation we may have under any contract
          with you to provide you with products and services. Also, any
          information published on our Platform may contain references or cross
          references to products, programs and services that are not announced
          or available in your country. Such references do not imply that we are
          intends to announce such products, programs, or services in your
          country. Contact us for information regarding the products, programs
          and services which may be available to you.
        </p>
        <h4>
          <strong>USE OF OUR PLATFORM AND SERVICES</strong>
        </h4>
        <ul>
          <li>
            <p>
              <strong>
                <u>Services we are offering</u>
              </strong>{" "}
              <strong>: </strong>AlignerBase Platform has been specially
              designed for Dentists, Orthodontists, Dental Practices, Dental
              Labs, Clear Aligner Companies etc. where we are offering Digital
              (CAD software based) design services. Currently we are offering
              design services for clear aligners but in future we may also
              provide design services including but not limited to crowns,
              abutments, surgical guides, dentures, partial frames, copings,
              frameworks, and other related dental prosthetic devices (the
              "Services") for dental appliances.
            </p>
            <p>
              Your use of our Platform and/or subscription to any of our
              services may include providing PHI, as such term is defined in our
              Business Associate Agreement&nbsp;(&ldquo;BAA&rdquo;). By using
              the our Platform, you understand and acknowledge that (i) we are
              not providing medical care, dental care, or treatment-related
              advice to you, we are merely a professional design service; and
              (ii) use of our Platform and its services are also governed by our{" "}
              <a href="https://www.alignerbase.com">
                Business Associate Agreement&nbsp;(&ldquo;BAA&rdquo;)
              </a>
              , as it may be modified over time.&nbsp; Any entity or
              organization (the &ldquo;Customer&rdquo;,&rdquo;
              Subscriber&rdquo;) who subscribe to our offered Services and
              usages rights of our Platform will be able to authorize their
              Client (each a "User" and collectively, the "Users") to use
              AlignerBase Platform. Such Users may or may not be employees of
              the Customer. &nbsp;For example, Users may include employees of
              the Customer, as well as non-employee dental labs, manufacturers
              and clinicians who have a relationship with the Customer.&nbsp;
            </p>
          </li>
        </ul>

        <ul>
          <li>
            <strong>
              <u>Eligibility</u>
            </strong>
            <strong>: </strong>To register in our Platform or subscribe to our
            services you must be at least 18 years old, or you have age of
            majority in accordance with your local law. We reserve the right, in
            its sole discretion, to deny any registration or subscription of
            anyone at any time and for any reason, whatsoever. With parental
            consent any individual under these age limits may sign up or
            subscribe to our services, provide any personal data to us, or
            otherwise submit personal data through our website and its services.
            We reserve the right, in its sole discretion, to deny any order of
            anyone at any time and for any reason, whatsoever. You certify that
            you are eighteen (18) years of age and agree to provide true,
            accurate, current, and complete information when prompted for such
            information.
          </li>
          <li>
            <strong>
              <u>Registration or Sign Up</u>
            </strong>
            <u>:</u> You may not require signing up or creating an account with
            us to visit our website and peruse its general contents. But to make
            any order or subscribe to our membership plan you need to sign up or
            register into our website through the customer sign up form, which
            may require you to provide certain information such as your First
            Name, Last Name, Email Address, Password etc. Upon registration or
            sign up, we may send you an email or phone verification code to
            verify your account and access to your member profile page. We
            hereby disclaim, and you hereby release us from any and all
            liability arising from any unauthorized registration and from any
            related claims, losses or damages alleged, arising from or relating
            to any unauthorized user registration and/or collection of personal
            information in connection with an unauthorized registration
          </li>
          <li>
            <strong>
              <u>Account and Password Usage:</u>
            </strong>{" "}
            It&rsquo;s important that the information associated with your
            account must be accurate and up to date. You are fully responsible
            for maintaining the confidentiality and security of any and all
            actions that take place while using your account and you must notify
            us right away of any actual or suspected scam, loss, theft, or
            unauthorized use of your account or account password. Under no
            circumstances may you share your account information with any other
            user or person. We are not responsible for any loss that results
            from unauthorized use of your email and password, with or without
            your knowledge.
          </li>
          <li>
            <strong>
              <u>User Communications</u>
            </strong>
            : By signing up or registering an account with us, you are opting in
            to receive communications from us regarding status updates of your
            cases being processed, and updates to our services, including
            newsletters, special offers and blog. You may opt out of marketing
            communications at any time by following the "unsubscribe" link at
            the bottom of any email. We may use email, live chat plugin and
            other forms of communication system as needed to communicate with
            our users.
          </li>
          <li>
            <strong>
              <u>Accessing or Using our Platform by your Clients</u>
            </strong>
            <strong>: </strong>The terms set forth in this clause apply to all
            Users of our Platform, whether such a User is a subscriber or
            customer of our platform or any client of such a subscriber. Each
            User agrees to all applicable terms to their use of our platform. In
            addition, each of our Subscribers or Customers is responsible for
            ensuring that all their clients authorized to use of our platform
            comply with the applicable terms of this terms of services when
            accessing or using our Platform. If you are a Client of any of our
            Subscriber your accessing and using of our Platform will be
            controlled by such subscriber through their accounts with us.
            &nbsp;The Subscriber who invited you to use our Platform has
            separately agreed to these terms of services, which permits such
            Subscriber to create and configure User accounts so that you and
            others may join as authorized Users. &nbsp;And upon using or
            accessing such user account you will be subject to abide all the
            terms of this terms of services.
          </li>
          <li>
            <p>
              <strong>
                <u>
                  Clients Responsibility for Finished Designs, Images and
                  Products
                </u>
              </strong>
              : You are fully responsible for all finished designs, images or
              other information provided which we will provide to you and/or
              laboratory(ies), manufacturers or clinicians of the dental
              products, such as if you are a prescribing clinician, you are
              responsible for viewing and approving the resulting designs from
              our Professional Services and if you are not the prescribing
              clinician, you are responsible for ensuring that the resulting
              designs from our Professional Services meets the requirements of
              its clinician client.&nbsp;
            </p>

            <p>
              We make no warranties or representations to Client, its Users or
              any third party(ies) regarding the resulting designs produced by
              us as part of our Professional Services, nor do we make any
              warranties or representation as to the dental products produced
              therefrom. We use our Platform to transmit and deliver the
              finished designs to the integrated manufacturers, laboratories,
              and clinicians as a file transfer system. The Client is solely and
              fully responsible and liable for the final product(s) produced.
              The Client has the option to &ldquo;Request Revision&rdquo; prior
              to the case being finalized to avoid any mistakes. &ldquo;Request
              Revision&rdquo; is highly recommending by Us. Once the client
              approves the design, then it is assumed that the Client is
              satisfied and accepts and assumes full responsibility for all
              dental products produced and/or any and all liability to any third
              party(ies) arising therefrom.
            </p>
          </li>
        </ul>

        <ul>
          <li>
            <strong>
              <u>Feedback and Content Submission</u>
            </strong>
            <u>:</u> Any comments, feedback, information, suggestions,
            submissions or materials you submit through or related to this
            Website (the &ldquo;Submission&rdquo;) will be considered
            non-confidential information and can be viewed by any user and will
            remain viewable until the suspension or termination of the member
            account with us. By making any such submission, you agree you have
            the right to do so free of any claims from any third party and agree
            to bear sole responsibility and liability for any claims related to
            such submissions. To the maximum extent permitted by law, we are not
            responsible for the content of any such submissions. By submitting
            any such submission, you irrevocably grant to us the right to use
            such submission in any and all forms of marketing and promotional
            material, whether now known or hereinafter developed, including,
            without limitation, publication, print advertisements
            (&ldquo;ads&rdquo;), online ads, television ads, radio ads,
            interactive media, as well as printed extracts and reproductions of
            any portion thereof, and for any and all other uses.
          </li>
          <li>
            <strong>
              <u>International Users</u>
            </strong>
            <strong>: </strong>Our website and its services is controlled,
            operated and administered by ALIGNERBASE LLC from our office within
            the United States. If you access our website or its services from a
            location outside the United States, you are responsible for
            compliance with all local laws. You agree that you will not use our
            website and its services in any country or in any manner prohibited
            by any applicable laws, restrictions or regulations.
          </li>
          <li>
            <strong>
              <u>Termination of Access</u>
            </strong>
            : We may decide to suspend or terminate your access to our platform
            and its services at any time without prior notice, for any reason.
            Common reasons include improper use of our website and its services,
            misrepresentations, fraudulent activity, and activity that violate
            these terms of services and any provision of our privacy policy.
          </li>
        </ul>

        <h4>
          <strong>PLANS AND PRICING </strong>
        </h4>
        <p>
          Please see our Plans and Pricing Policy here{" "}
          <a href="https://www.alignerbase.com/plans-and-pricing-policy">
            www.alignerbase.com/plans-and-pricing-policy
          </a>{" "}
          Please note that this Plans and Pricing Policy is a part of these
          Terms of Services.
        </p>
        <h5>
          <strong>INTELLECTUAL PROPERTY RIGHTS OR COPYRIGHT</strong>
        </h5>
        <p>
          All content including but not limited to our business name, logos,
          images, trademarks, service marks, designs, icons, graphics, training
          videos, content, platform software and all other information or
          materials (the &ldquo;content&rdquo;, &ldquo;materials&rdquo;) used
          and published on our website are legally owned by&nbsp;ALIGNERBASE LLC
          and protected by protected by The Copyright Act of 1976, Digital
          Millennium Copyright Act (DMCA) 1998 and other applicable USA
          intellectual property law. No Content may be modified, copied,
          distributed, framed, reproduced, republished, downloaded, displayed,
          posted, transmitted, or sold in any form or by any means, in whole or
          in part, without our written permission. You may not use any meta tags
          or any other &ldquo;hidden text&rdquo; utilizing AlignerBase&rsquo;s
          name or trademarks without the express written consent from us. You
          may download or print a copy of any portion of the content from our
          website, only for the personal or non-commercial or educational use.
          You may not republish any of our content on any other place of
          internet or extranet or incorporate the information in any other
          database or compilation. Any other use of the content is strictly
          prohibited.{" "}
          <strong>
            Any personal or commercial use of any content, image, design, logo,
            videos, trademark, service mark or any other information inserted in
            this website is strictly prohibited to any unauthorized use. Any
            unauthorized use of such content, business name{" "}
          </strong>
          (including using the words &ldquo;AlignerBase&rdquo; in any business
          name, email, or URL without written authorization from us),
          <strong>
            {" "}
            images, trademarks, service marks, logos, icons, graphics, platform
            software and all other information appeared on our website
          </strong>
          <strong>, </strong>
          <strong>will deem to be the violation of&nbsp; </strong>The Copyright
          Act of 1976, Digital Millennium Copyright Act (DMCA), 1998 , other
          applicable federal or states law of the USA and international&nbsp;
          intellectual property law.
        </p>
        <h5>
          <strong>DMCA POLICY:</strong>
        </h5>
        <p>
          We honor and comply with The Digital Millennium Copyright Act,
          1998(DMCA). We will remove infringing materials under the Digital
          Millennium Copyright Act (&ldquo;DMCA&rdquo;) if properly notified
          that a User Submission infringes copyright. If you believe that your
          work has been copied in a way that constitutes copyright infringement,
          please notify us in writing. We will expeditiously respond to valid
          notices of copyright infringement that adhere to the requirements set
          forth in the Digital Millennium Copyright Act (DMCA). If you believe
          that your work has been copied in a way that constitutes copyright
          infringement, you may contact us at{" "}
          <a href="mailto:info@alignerbase.com">info@alignerbase.com</a> with a
          notice in writing that contains all six points enumerated below
          (preferably via email).
        </p>
        <h5>
          <strong>NOTICE AND TAKEDOWN PROCEDURE</strong>
        </h5>
        <p>
          If you believe that your work has been copied in a way that
          constitutes copyright infringement, you may contact us with a notice.
          Your notice must contain the following information
        </p>
        <ol>
          <li>
            A description of the copyrighted work or material or content that
            you claim has been infringed;
          </li>
          <li>
            A description or link of the material that you claim to be
            infringing, and a description or link of where the material that you
            claim is infringing is located on the Service, &nbsp;which should be
            sufficient for us &nbsp;to locate such material;
          </li>
          <li>Your address, telephone number, and email address;</li>
          <li>
            A statement by you that you have a good faith belief that the
            disputed use is not authorized by the copyright owner, its agent, or
            the law; (
            <em>
              I.e.: I have a good faith belief that the use of the copyrighted
              material I am complaining of is not authorized by the copyright
              owner, its agent, or the law
            </em>
            )
          </li>
          <li>
            A statement by you that the information in your notice is accurate
            and, under penalty of perjury, that you are the copyright owner or
            authorized to act on the copyright owner&rsquo;s behalf{" "}
            <em>
              (i.e. The information in this notice is accurate and, under
              penalty of perjury, I am the owner, or authorized to act on behalf
              of the owner, of the copyright or of an exclusive right that is
              allegedly infringed
            </em>
            )
          </li>
          <li>
            An electronic or physical signature of the of the owner of the
            copyright or a person authorized to act on behalf of the owner of
            the copyright.
          </li>
        </ol>
        <p>
          Upon receipt of a valid notice and conducting a proper investigation,
          if we find any infringement, we will remove such content from our
          website or app(s). If any necessary information is missing from your
          notice, it may be rejected.
        </p>
        <h5>
          <strong>Designated Copyright Agent</strong>
        </h5>
        <p>
          Our designated copyright agent for notice of alleged copyright
          infringement is:
        </p>
        <p>Alignerbase LLC</p>
        <div>
          <strong>Address:</strong>&nbsp;1007 N ORANGE ST STE 1357
        </div>
        <div>4TH FLOOR</div>
        <div>WILMINGTON</div>
        <div>DE 19801</div>

        <p>
          Attn: Copyright Agent
          <br /> <a href="mailto:info@alignerbase.com">info@alignerbase.com</a>
        </p>

        <h5>
          <strong>PRIVACY POLICY</strong>
        </h5>
        <p>
          By using our platform and its Services, all the policy concerning you
          or any of our users will be conducted by our &ldquo;Privacy
          Policy&rdquo; inserted at the bottom of every page of our website or
          you may find it here{" "}
          <a href="https://www.alignerbase.com/privacy-policy">
            www.alignerbase.com/privacy-policy
          </a>
        </p>
        <h5>
          <strong>PROHIBITED ACTIVITIES</strong>
        </h5>
        <p>
          To use our website and its services you must behave in a peaceful,
          civil, prudent and respectful manner at all times. You do not, or
          attempt to do, anything that is illegal; anything prohibited by any
          laws which applies to our website and its services or which applies to
          you or your use of our website and its services or any other user of
          our website, anything which we would consider inappropriate; or
          anything which might bring us or our user into disrepute. Moreover,
          you will not and it is strictly prohibited to:
        </p>
        <ul>
          <li>
            Act like specious manner by, among other things, impersonating any
            person;
          </li>
          <li>Harass or stem any other user of our Platform;</li>
          <li>Distribute "spam";</li>
          <li>
            Promote information that is false or misleading, or promote illegal
            activities or conduct that is defamatory, libelous or otherwise
            objectionable;
          </li>
          <li>"frame" or "mirror" any part of our website or platform;</li>
          <li>
            Attempt to or actually access our Platform by any means other than
            through the interfaces provided by us;
          </li>
          <li>
            Modify, adapt, sublicense, translate, sell, reverse engineer,
            decipher, decompile or otherwise disassemble any portion of our
            website or its content or any software used on or for the Website,
            or motivate others to do so;
          </li>
          <li>
            Post, submit, use, transmit or distribute, directly or indirectly,
            (e.g. screen scrape) in any manner or media any content or
            information obtained from our Website in connection with your use of
            the Service in accordance with this terms and condition;
          </li>
          <li>
            Post any content or comment which is offensive to the online
            community, influence racism, influence gender discrimination,
            hatred, the physical harm of any kind, or is otherwise objectionable
            or non-acceptable against any group, community or individual;
          </li>
          <li>
            Post any content or information that you know is false or misleading
            or promotes illegal activities or conduct that is abusive,
            threatening, obscene, defamatory, or libelous;
          </li>
          <li>
            post any content which display pornographic or sexually explicit
            material of any kind, or exploits people in a sexual or violent
            manner;
          </li>
          <li>
            Collect information about another user which may prohibit and
            suspicious in nature;
          </li>
          <li>
            Use our platform in a manner that may harm minors or that interacts
            with or targets people who are under the age of eighteen;
          </li>
          <li>Advertise or solicit other&rsquo;s copyright information;</li>
          <li>
            Upload, post or otherwise make available on the Platform any
            material protected by copyright, trademark or other proprietary
            right without the express permission of the owner of the copyright,
            trademark or other proprietary right. You will indemnify us for any
            claims by third parties of infringement of copyrights, trademarks,
            proprietary rights or any other harm resulting from such a
            submission of material by you or any of your Users.
          </li>
          <li>
            Promote fraudulent schemes, get rich quick schemes, online gaming,
            and gambling, cash gifting, work from home businesses, or any other
            dubious money-making ventures;
          </li>
          <li>
            Take part in any activity that in any way deem to be void or illegal
            under any law;
          </li>
        </ul>
        <h4>
          <strong>MARKETING OPT-OUTS:</strong>
        </h4>
        <p>
          If you are receiving any special offers from us or any marketing
          communication via email, SMS text message, or otherwise, you can
          unsubscribe via the links provided in the email at any time or by
          contacting us at&nbsp;
          <a href="mailto:info@alignerbase.com">info@alignerbase.com</a> or text
          instructions regarding opting out. By opting to receive special offers
          from us, you consent to receiving, from time to time, text messages or
          emails which may include alerts, promotional offers etc. Message
          frequency varies based on user and other factors.
        </p>
        <h4>
          <strong>YOUR REPRESENTATIONS AND WARRANTIES </strong>
        </h4>
        <p>
          You represent and warrant that (a) your use of our website and its
          services will be in strict accordance with these terms of services and
          with all applicable laws and regulations, including without limitation
          any local laws or regulations in your country, state, city, or other
          governmental areas, regarding online conduct and acceptable content,
          and regarding the transmission of technical data exported from the
          United States or the country in which you reside and (b) your use of
          our website will not infringe or misappropriate the intellectual
          property rights of any third party. To the maximum extent permitted by
          applicable law, we makes no warranty of any kind as to the services or
          any designs resulting therefrom, whether express, implied, statutory
          or otherwise, and specifically disclaims all implied warranties,
          including any implied warranty of merchantability, fitness for a
          particular purpose or non-infringement.
        </p>
        <h4>
          <strong>LIMITATION OF LIABILITY</strong>
        </h4>
        <p>
          You acknowledge and agree that your access to and use of website and
          its services is at your own risk. All direct or indirect risk related
          to use of our website or platform and its services is borne entirely
          by you. We shall not be liable to you for any direct, indirect,
          incidental, special, consequential or exemplary damages, including but
          not limited to, special, direct, indirect, incidental, punitive, or
          consequential damages of any kind resulting from the use or the
          inability to use our website and its services. In relation to the
          access and use of our website or platform and its services or any of
          its services, you hereby also acknowledged and agreed that
        </p>
        <ul>
          <li>
            We reserve the right to restrict or limit its access or visibility
            to any of our user at any time;
          </li>
          <li>
            Our liability will be limited to you or any other person for any
            direct, indirect, or special damages or losses resulting from the
            use or unable to use our website or platform and its services or any
            content or services therein;
          </li>
          <li>
            The liability of us shall be limited to you or any other persons for
            any direct, indirect, or special damages or losses of health or
            medical conditions, resulting from the use of our website and its
            services or any content in it;
          </li>
          <li>
            These terms of services will be limited to you, if any loss or
            damage arises because of any theft of property belonging to you or
            for any loss or damage, in which you have any contribution to such
            loss or damage;
          </li>
          <li>
            We are not responsible or liable for any threatening, defamatory,
            obscene, offensive or illegal contents or conduct of any other
            parties or any infringement of any other&rsquo;s rights, including
            copyrights. If you are dissatisfied with any of the terms of this
            terms of services document or any other policy outlined in our
            website and its services, the sole and exclusive remedy available to
            you is to discontinue using our website and its services;
          </li>
          <li>
            In no event we will be liable for any injury, loss, claim, or any
            direct, indirect, incidental, punitive, special, or consequential
            damages of any kind, including, without limitation, lost profits,
            lost revenue, lost savings, loss of data, replacement costs, or any
            similar damages, whether based in contract, tort (including
            negligence), strict liability or otherwise, arising from your use ,
            including, but not limited to, any errors or omissions in any
            content of our website and its services;
          </li>
          <li>
            We reserve the right, in our sole discretion to investigate and take
            appropriate legal action against anyone who violates our terms of
            services and privacy policy.
          </li>
        </ul>
        <h5>
          <strong>GOVERNING LAW AND JURISDICTION </strong>
        </h5>
        <p>
          These Terms are governed by and will be construed in accordance with
          the laws of the state of Delaware, USA, without regard to conflicts of
          law provisions thereof and without regard to the other State Law of
          the USA and international law which and where it is applicable and
          needs to enforce these Terms of services. If any part of these terms
          of services is deemed unlawful, void, or for any reason unenforceable
          then that part will be deemed severable and will not affect the
          validity and enforceability of the remaining parts. Any dispute or
          claim arising out of or in connection with these terms of services
          shall be subject to the exclusive jurisdiction of the state courts
          sitting in Delaware, USA or we may have sole right to choose the
          territorial jurisdiction of the user and you agree that such courts
          will have personal jurisdiction over you in such matters through your
          use of our website and its services
        </p>
        <h4>
          <strong>DISPUTES MANAGEMENT</strong>
        </h4>
        <p>
          If there is any dispute, claim, or complaint about or involving the
          use of our website and its services or in connection with these terms
          of services it will be managed by our support center first. If matters
          progress to a legal stage, our attorney will arrange a time to talk to
          the user. To the extent any complaint, dispute, or controversy
          regarding the use of our website and its service is not arbitrable
          under applicable laws, or otherwise, you and us both agree that any
          claim or dispute regarding our website and its services will be
          resolved exclusively in accordance with these terms of services. Also,
          subject to these terms of services, any dispute, claim, or complaint
          between the users will be managed by our support center first.
        </p>
        <h4>LEGAL LIMITATIONS</h4>
        <p>
          As some jurisdictions do not allow some of the exclusions or
          limitations as established in these terms of services some of these
          exclusions or limitations may not apply to you. In that event, the
          liability will be limited as far as legally possible under the
          applicable legislation. We may plead these terms of services in a bar
          to any claim, action, proceeding or suit brought by you, against us
          for any matter arising out of any complaint or otherwise in respect of
          these terms of services.
        </p>
        <h4>
          <strong>MISCELLANEOUS</strong>
        </h4>
        <p>
          These miscellaneous provisions are part of just about every online
          term of services to ensure its enforceability. You agree that no joint
          venture, partnership, employment, or agency relationship exists
          between you and us as a result of the terms of services. You may not
          assign, delegate, or otherwise transfer your account or your
          obligations under these terms of services. We have the right, in our
          sole discretion, to transfer or assign all or any part of our rights
          under these terms of services. We have the right to delegate or use
          third-party contractors to fulfill our duties and obligations under
          these terms of services in connection with our website and its
          services.
        </p>
        <h4>
          <strong>THIRD-PARTY LINKS</strong>
        </h4>
        <p>
          Our website and its services may contain third-party website or links
          or resources. You acknowledge and agree that we are not responsible or
          liable for the availability or accuracy of such links or resources or
          for the content, products, or services on or available from those
          links. Linking with that type of websites or resources does not
          intimate any affiliation with us of such websites or resources or the
          content, products, or services available from such websites or
          resources. Your sole responsibility for and assume all risk arising
          from your use of any such websites or resources or the content,
          products or services on or available from such websites or resources
          review carefully their policies and practices and make sure you
          understand them before you engage in any transaction. Complaints,
          claims, concerns, or questions regarding third-party products should
          be directed to the third-party.
        </p>
        <h4>
          <strong>INDEMNIFICATION</strong>
        </h4>
        <p>
          You agree to defend, indemnify, and hold ALIGNERBASE LLC and/or its
          officers, directors, employees, agents, licensors, and suppliers,
          harmless from and against any claims, actions or demands, liabilities
          and settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your use
          of our website and its services and/or violation of any terms of these
          terms of services. You as a user or subscriber also agree to indemnify
          and hold harmless each other from any damages, costs, and expenses. We
          reserve the right to assume the exclusive defense and control of any
          demand, claim, or action arising here under or in connection with our
          website or platform and all negotiations for settlement or compromise.
          You agree to fully cooperate with us in defense of any such demand,
          claim, action, and settlement or compromise negotiations, as requested
          by us.
        </p>
        <h4>
          <strong>SEVERABILITY</strong>
        </h4>
        <p>
          If any term or provision of these terms of services is invalid,
          illegal or unenforceable, such invalidity, illegality or
          unenforceability shall not affect any other term or provision of these
          terms of services and the remaining provisions of shall remain in full
          force.
        </p>
        <h4>
          <strong>NOTICES: </strong>
        </h4>
        <p>
          You must deliver all notices or other communications required or
          permitted under this terms of services in writing to us at the address
          listed below, by certified or registered mail (postage prepaid and
          return receipt requested), or by a nationally-recognized express mail
          service or via email or through other electronic platform.&nbsp;
          Notice will be effective upon receipt of delivery. We may change its
          address for receipt of notice by giving notice of such change to the
          user.
        </p>

        <h4>
          <strong>CHANGE OR MODIFICATION </strong>
        </h4>
        <p>
          From time to time, with the needs of any necessary circumstances,
          these terms of services can be edited, modified, or changed without
          any prior notice. Your regular use of our website and its services
          will indicate your consent to such changes. We reserve the right to
          change these terms of services at any time without notice to you by
          posting changes on the website or by updating the website to
          incorporate the new terms of services. Upon making any material
          changes to this terms and condition we will send you an email
          notification about such changes or modification. You are responsible
          for regularly reviewing information posted online to obtain timely
          notice of such changes. Your regular use of our website and its
          services and its services will indicate your consent to such changes.
        </p>
        <h4>
          <strong>ENTIRE AGREEMENT</strong>
        </h4>
        <p>
          The failure of us to exercise or enforce any right or provision of
          these terms of services shall not constitute a waiver of such right or
          provision.&nbsp;These terms of services constitute the sole and entire
          agreement between you and us regarding your use of our website or
          platform and its services and supersede all prior and contemporaneous
          understandings, agreements and warranties, both between you and us
          regarding use of our website and its services.
        </p>
        <h4>
          <strong>WAIVER: </strong>
        </h4>
        <p>
          Our failure to exercise any right, power or privilege under any terms
          of this Terms of Services will not be construed as a waiver of any
          subsequent or further exercise of that right, power or privilege or
          the exercise of any other right, power or privilege.
        </p>
        <h4>
          <strong>ACCEPTANCE </strong>
        </h4>
        <p>
          <strong>
            By using our website and its services you signify your acceptance to
            these terms of services and our privacy policy. Your continuous use
            of our website and its services will be deemed to be your acceptance
            of any changes of these terms of services
          </strong>
        </p>
        <h4>
          <strong>CONTACT US</strong>
        </h4>
        <p>
          We have sole jurisdiction to provide an explanation of these terms and
          condition. If you have any question about this terms and condition or
          would like to access or modify your personal identifiable information,
          please contact us.
        </p>
        <div>
          <strong>ALIGNERBASE LLC,</strong>
        </div>
        <div>
          <strong>Address:</strong>&nbsp;1007 N ORANGE ST STE 1357
        </div>
        <div>4TH FLOOR</div>
        <div>WILMINGTON</div>
        <div>DE 19801</div>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:info@alignerbase.com">info@alignerbase.com</a>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <h5>
          <strong>FYI:</strong>&nbsp;<strong>Please note that</strong>
        </h5>
        <ul>
          <li>
            This terms of services document was last updated in January, 2023.
          </li>
          <li>
            We reserve the right to change these terms and condition at any
            time. Modifications to these terms of services can be made without
            prior notification.
          </li>
          <li>
            Continued use of our website or platform signifies your acceptance
            of any changes to these terms of services.
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default TermsOfService
